var LISTINGS_MANAGER_MPV_ID = 'listingsManager';
var WEBSITES_MPV_ID = 'websitesTbyb';
var DOMAINS_MPV_ID = 'domainName';
var DIWY_MPV_ID = 'VistaXWixDIWY';
var LOGO_MAKER_MPV_ID = 'logoMaker';
var VISTA_X_WIX_PLUS_SUBSCRIPTION_MPV_ID = 'VistaXWixPlusSubscription';
var CONNECTED_BUSINESS_CARDS_MPV_ID = 'connectedBusinessCards';
var WIX_WEBSITES_MPV_ID = 'wixWebsites';
var QR_CODE_GENERATOR_MPV_ID = 'qrCodeGenerator';
var PRO_ADVANTAGE_MPV_ID = 'proAdvantage';
var GOOGLE_WORKSPACE_MPV_ID = 'vistaXWixGoogleWorkspace';
var NEW_PLATFORM_ROLLOUT_LOCALES = [
    'en-IE',
    'es-ES',
    'en-CA',
    'fr-CA',
    'en-AU',
    'en-NZ',
    'en-SG',
    'en-GB',
    'pt-PT',
    'it-IT',
    'en-US',
    'es-US',
    'fr-FR',
    'de-AT',
    'de-CH',
    'de-DE',
    'fr-CH',
    'it-CH',
    'nl-BE',
    'fr-BE',
    'nl-NL',
    'da-DK',
    'nb-NO',
    'sv-SE',
];
var WIX_UNSUPPORTED_LOCALES = ['en-IN', 'ja-JP', 'fi-FI'];
var WIX_DOMAINS_VISTA_BILLED_LOCALES = [];

export { CONNECTED_BUSINESS_CARDS_MPV_ID, DIWY_MPV_ID, DOMAINS_MPV_ID, GOOGLE_WORKSPACE_MPV_ID, LISTINGS_MANAGER_MPV_ID, LOGO_MAKER_MPV_ID, NEW_PLATFORM_ROLLOUT_LOCALES, PRO_ADVANTAGE_MPV_ID, QR_CODE_GENERATOR_MPV_ID, VISTA_X_WIX_PLUS_SUBSCRIPTION_MPV_ID, WEBSITES_MPV_ID, WIX_DOMAINS_VISTA_BILLED_LOCALES, WIX_UNSUPPORTED_LOCALES, WIX_WEBSITES_MPV_ID };
