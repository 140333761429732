import React from 'react';

import { BoundedContent, FlexBox, Spinner } from '@vp/swan';

const Loading: React.FC = () => {
  return (
    <BoundedContent py={{ xs: 6, sm: 6, md: 9 }}>
      <FlexBox justifyContent="center" alignItems="center">
        <Spinner accessibleText="Loading..." />
      </FlexBox>
    </BoundedContent>
  );
};

export default Loading;
